/**
* api接口统一管理
*/
import { axios } from '@/utils/request'

export const listFirmDepartment = params => axios({
    url: '/api/base/system/firm/departmentManagement/list',
    method: 'get',
    params,
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    }
})

export const addFirmDepartment = params => axios({
    url: '/api/base/system/firm/departmentManagement/add',
    method: 'post',
    data: JSON.stringify(params),
    headers: {
        'Content-Type': 'application/json;charset=UTF-8'
    }
})

export const editFirmDepartment = params => axios({
    url: '/api/base/system/firm/departmentManagement/edit',
    method: 'post',
    data: JSON.stringify(params),
    headers: {
        'Content-Type': 'application/json;charset=UTF-8'
    }
})

export const delFirmDepartment = params => axios({
    url:'/api/base/system/firm/departmentManagement/firmDel/' + params,
    method: 'post',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    }
})

export const selectByIdFirmDepartment = params => axios({
    url: '/api/base/system/firm/departmentManagement/selectFirmById/' + params,
    method: 'get',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    }
})
